<template>
  <section v-if="user.is_staff==true">
    <div class="container">
      <div class="columns">
        <div class="column">
          <DatePicker
              v-model="date"
              name="date"
              title-position="left"
              color="blue"
              is-dark
              is-expanded
              :min-date="offsetMonth(3)"
              :max-date="now"
              :disabled-dates='{ weekdays: [1, 7] }'
              mode='date'
              :timezone="timezone"
              @select-date="selectDate"
              @click="selectDate(date)"
          />
        </div>
        <div class="column">
          <div class="table-container">
            <table class="table is-fullwidth">
              <thead>
              <th>Product</th>
              <th>Cusip</th>
              <th>Rate</th>
              <th>Close</th>
              <th>Repo drop</th>
              <th>Accrued</th>
              </thead>
              <tbody>
              <template v-if="repos.length">
                <tr v-for="(item, index) in repos" :key="index">
                  <td >{{ item.product_spec?.base_name }}</td>
                  <td>{{ item.bond?.cusip }}</td>
                  <td>
                      <span v-if="!isRateEditable">
                         {{ inputrate[index]=item.rate }}
                      </span>

                    <div v-if="isRateEditable" class="field has-addons">
                      <label class="label" for="inputrate"></label>
                      <div class="control">
                        <input v-model="inputrate[index]" type="number" name="inputrate" class="input">
                      </div>
                    </div>
                  </td>
                  <td>
                      <span v-if="!isRateEditable">
                         {{ closerate[index]=item.close }}
                      </span>

                    <div v-if="isRateEditable" class="field has-addons">
                      <label class="label" for="closerate"></label>
                      <div class="control">
                        <input v-model="closerate[index]" type="number" name="closerate" class="input">
                      </div>
                    </div>
                  </td>
                  <td>{{ item.repo_drop ? item.repo_drop : 'nil' }}</td>
                  <td>{{ item.accured ? item.accured.toFixed(8) : 'nil' }}</td>
                </tr>
              </template>

              <template v-else>
                <tr v-for="(item, index) in filteredProducts" :key="index" >
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.product.bond.cusip }}</td>
                  <td>
                      <span v-if="!isRateEditable">
                        {{ inputrate[index]=item.rate }}
                      </span>

                    <div v-if="isRateEditable" class="field has-addons">
                      <label class="label" for="inputrate"></label>
                      <div class="control">
                        <input v-model="inputrate[index]" type="number" name="inputrate" class="input">
                      </div>
                    </div>
                  </td>
                  <td>
                      <span v-if="!isRateEditable">
                        {{ closerate[index]=item.close }}
                      </span>

                    <div v-if="isRateEditable" class="field has-addons">
                      <label class="label" for="closerate"></label>
                      <div class="control">
                        <input v-model="closerate[index]" type="number" name="closerate" class="input">
                      </div>
                    </div>
                  </td>
                  <td>{{ dropdata[index]=item.repo_drop }}</td>
                  <td>{{ accured[index]=item.accured.toFixed(8) }}</td>
                </tr>
              </template>

              <tr>
                <td colspan="5">
                  <div v-if="isRateEditable" class="buttons">
                    <button class="button is-info" @click="onEditRepo()">
                        <span class="icon is-small">
                          <i class="fas fa-check"></i>
                        </span>
                    </button>
                    <button class="button is-danger" @click="isRateEditable = !isRateEditable">
                        <span class="icon is-small">
                          <i class="fas fa-times"></i>
                        </span>
                    </button>
                  </div>
                  <div v-if="!isRateEditable" class="buttons">
                    <button class="button is-info" @click="isRateEditable = !isRateEditable">
                        <span class="icon is-small">
                          <i class="fas fa-pen"></i>
                        </span>
                      <span>Edit</span>
                    </button>
                    <button v-if="!push_to_prime" class="button is-info" :disabled="True" @click="modalPushToDraxprime = true">
                        <span class="icon is-small">
                          <i class="fa-solid fa-jet-fighter-up"></i>
                        </span>
                      <span>Push to DraxPrime</span>
                      <VModal
                          :title="'Push To DraxPrime Final step'"
                          :open="modalPushToDraxprime"
                          size="small"
                          actions="center"
                          @close="modalPushToDraxprime = false">

                        <template v-if="repos.length" #content >
                          <div style="color: #a2a5b9"><b> you sure you want to push to DraxPrime ?</b></div>
                          <tr v-for="(item, index) in repos" :key="index">
                            <td style="color: #a2a5b9; padding: 0.4em 1.4em!important;">{{ item.product_spec?.base_name }}</td>
                            <td style="color: #a2a5b9; padding: 0.4em 1.4em!important;">{{ item.bond?.cusip }}</td>
                            <td style="color: #a2a5b9; padding: 0.4em 1.4em!important;">{{ item.accured ? item.accured.toFixed(8) : 'nil' }}</td>
                        </tr>
                        </template>
                          <template #action>
                            <VButton color="primary" raised @click="onPushToPrime(date)">Go for it</VButton>
                            <VButton color="primary" raised @click="$emit('modalPushToDraxprime=true')">Close</VButton>
                        </template>
                      </VModal>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>



<script>
import {DatePicker} from 'v-calendar';
import VModal from '../../components/base/modal/VModal.vue'
import VButton from "../../components/base/button/VButton.vue";
import store, {ActionTypes} from '../../store'
import {mapActions, mapState} from 'vuex'
export default {
  name: 'RepoHistory',
  components: {
    DatePicker,
    VModal,
    VButton,
  },
  beforeRouteEnter(to, from, next) {
   // store.dispatch(ActionTypes.FETCH_PRODUCTS_REPO).then(() =>
        store.dispatch(ActionTypes.FETCH_REPOS).then(() => next())


  },
  data() {
    return {
      filteredProducts: [],
      modalPushToDraxprime:false,
      isRateEditable: false,
      inputrate: [],
      closerate: [],
      dropdata: [],
      accured: [],
      selectedDate: '',
      date: new Date(),
      product_repo:this.$store.state.products_repo,
      push_to_prime:this.$store.state.push_to_prime,
      action:open,
      timezone:'UTC'
    }
  },
  computed: {
    ...mapState(['repos', 'user', 'products_repo','push_to_prime']),
    now() {
      return Date.now()
    }
  },
  async mounted() {
    await store.dispatch(ActionTypes.FETCH_PRODUCTS_REPO)

    if (this.products_repo) {
      this.filteredProducts = this.products_repo.filter((product) => product.name.includes('CT')).map((product) => {
        return {
          product,
          rate: 0,
          close: false,
          repo_drop: 0,
          accured: 0,
        }
      })
    }

    this.selectDate(new Date());
  },
  methods: {
    ...mapActions({
      fetchProductsRepo: ActionTypes.FETCH_PRODUCTS_REPO,
      fetchRepos: ActionTypes.FETCH_REPOS,
      submitRepos: ActionTypes.CREATE_REPOS,
      pushToPrime: ActionTypes.PUSH_TO_PRIME,
    }),

    async selectDate(date) {
      let date_request=date?.toJSON().split('T')[0]
      this.fetchRepos(date_request)
      this.product_repo= await this.fetchProductsRepo(date_request)
      selectedDate:this.date_request
      //console.log(this.product_repo)
      if (this.product_repo) {
        this.filteredProducts =this.products_repo.filter((product) => product.name.includes('CT')).map((product) => {
          return {
            product,
            rate: 0,
            close: 0,
            repo_drop: 0,
            accured: 0,
          }
        })
      }
      this.isRateEditable = false
      this.selectedDate = date?.toJSON().split('T')[0]
    },
    onEditRepo() {
      this.fetchProductsRepo(this.selectedDate)
      const payload = this.filteredProducts.map((item, index) => {
        return {
          bond: item.product.bond.id,
          product_spec: item.product.product_spec.id,
          date: this.selectedDate,
          rate: this.inputrate[index],
          close: this.closerate[index],
          repo_drop: this.dropdata[index],
          accured: this.accured[index],
          index: index + 1 //leave index cause it allows to edit data before commit
        }
      })
      this.submitRepos(payload);
      this.selectDate(this.date)

      this.isRateEditable = false;
    },
    onPushToPrime(date){
      let date_request=date?.toJSON().split('T')[0]
      return this.pushToPrime(date_request)
    },
    sortItems(itemArray) {
      return Object.keys(itemArray)
          .sort()
          .reduce((acc, key) => ({
            ...acc, [key]: itemArray[key]
          }), {});
    },
    offsetMonth(months) {
      return new Date(new Date().getFullYear(), new Date().getMonth() - months, new Date().getDate())
    },
  },


}

</script>

<style scoped>
.is-one-third {
  display: flex;
  justify-content: center;
}
.inner-content{
  color: #a2a5b9!important;
}
.delete-index{
  color: #a2a5b9;
}
.index-copy a{
  color: #a2a5b9;
}
.user-select{
  color: #a2a5b9;
}
.modal {
&.is-big {
.modal-content {
  max-width: 100%;
}
}
}
</style>
